export const SystemVersion = "1.0.2(b)";

export const WebsiteMessage = [
    {
        "NotificationType": "System",
        "ACID": "C0000",
        "Message": "Fix loading time too long:",
        "Date": "26-11-2024",
        "EnableTo": "3-12-2024",
        "Path": "/C0211",
        "UpdateBy": "Alex",
        "Published": false
    }
]

export const UpdatePatchNote = [
    {
        "Version": "1.0.1",
        "Date": "27-11-2024",
        "Message": "Fixed bugs in previous functionality",
        "Content": [{
            "Type": "Added",
            "Points": [
                "Display loading bar during login",
                "Show red border as a warning if required fields are missing",
                "Ability to reset Product and User Images to default settings",
            ]
        },{
            "Type": "Changed",
            "Points": [
                "Reverted to You Tong (displaying only one address)",
                "Email address 'info@wisetechinterglobe.com.hk' appears as a single line",
                "Replaced 'For You - Dai Chun' with 'Replace'",
                "Label position updated from 'Wisetechinterglobe' to 'Wisetech Interglobe'",
                "Homepage (Our Mission) - Standardized all commas and removed unnecessary question marks",
                "Pricing Table (Maintenance cost is 10% of development cost)",
                "Link 'Wisetech Interglobe Limited 滙文智庫有限公司 ©2024' to homepage",
                "Updated date of birth format to 'dd/mm/yyyy'",
                "Corrected 'Cityzen' to 'Citizen'",
                "Product image upload and improved error messaging",
                "Improved management system message: 'You can access the program anytime, anywhere through a computer to view various data, making work easier, more convenient, and faster.'",
                "Removed the question mark at the end of the sentence under 'Our Advantages'",
                "Corrected 'Salse' to 'Sales'",
                "Corrected the username length message: 'Your username must be at least 5 characters long.'",
                "Warehouse 4, 6, 10 are sorted by number",
                "SKU Price, Arrival Date, and Weight fields cannot be less than 0",
                "Saved Azure storage link as a constant",
                "Refactored each type of function to a component",
            ]
        },{
            "Type": "Deprecated",
            "Points": [

            ]
        }, {
            "Type": "Removed",
            "Points": [
                "Users are no longer able to change product categories",
            ]
        }, {
            "Type": "Fixed",
            "Points": [
                "Fixed the issue of prolonged loading time",
                "Fixed image upload failures",
                "Fixed the issue where editing personal information would not update the user list",
                "Fixed the issue with Inbound/Outbound updates failing",
            ]
        }, {
            "Type": "Security",
            "Points": [

            ]
        }]
    },{
        "Version": "1.0.2(a)",
        "Date": "03/12/2024",
        "Message": "Add new function and fixed bugs in previous functionality",
        "Content": [{
            "Type": "Added",
            "Points": [
                "Introduced a new feature allowing users to check SKU references.",
                "Added a user list (logout option) in the top right corner of the page.",
                "Added a Refresh button to the Product section.",
                "Added a Logout button to the C0217 Dashboard.",
                "Updated the Product list to include sorting by Type.",
                "Added a cookies for user quick select login ID.",
                "Added a new column to set SKU Order Limit.",
                "Added a Resize Layout button",
            ]
        },{
            "Type": "Changed",
            "Points": [
                "Redesigned the image upload function for better usability.",            ]
        },{
            "Type": "Deprecated",
            "Points": [

            ]
        }, {
            "Type": "Removed",
            "Points": [
                "Removed the login function from the Navbar.",
                "Removed the product test upload button.",
            ]
        }, {
            "Type": "Fixed",
            "Points": [
                "Fixed the issue where the user could not upload the product image.",
                "Fixed the C0217 filter display at 80% issue."
            ]
        }, {
            "Type": "Security",
            "Points": [

            ]
        }]
    },{
        "Version": "1.0.2(b)",
        "Date": "13/01/2025",
        "Message": "Add new function and fixed bugs in previous functionality",
        "Content": [{
            "Type": "Added",
            "Points": [
            ]
        },{
            "Type": "Changed",
            "Points": [
                "SKU Ref option move to SKU Information (C0211)",            ]
        },{
            "Type": "Deprecated",
            "Points": [

            ]
        }, {
            "Type": "Removed",
            "Points": [

            ]
        }, {
            "Type": "Fixed",
            "Points": [

            ]
        }, {
            "Type": "Security",
            "Points": [

            ]
        }]
    }
]
