import { useState } from 'react'
import RepairRecord from '../../../Components/C0217/Shop/SearchRecord/RepairRecord'

const RepairEquipmentRecordPage = () => {

    const [isStartDate, setStartDate] = useState<String>("")

    const content = (
        <>
            <RepairRecord />
        </>
    )

    return content
}

export default RepairEquipmentRecordPage