import css from '../../../../css/C0217/RepairRecord.module.css'
import DatePicker from 'react-date-picker';

const SelectOption = [
    {
        "value": "爐具(電)",
        "label": "爐具(電)"
    }, {
        "value": "爐具(煤)",
        "label": "爐具(煤)"
    }, {
        "value": "雪櫃/凍櫃/冰水池",
        "label": "雪櫃/凍櫃/冰水池"
    }, {
        "value": "安心出行手機",
        "label": "安心出行手機"
    }, {
        "value": "電力裝置/跳電掣",
        "label": "電力裝置/跳電掣"
    }, {
        "value": "水喉/渠務",
        "label": "水喉/渠務"
    }, {
        "value": "收銀機無法連線",
        "label": "收銀機無法連線"
    }, {
        "value": "電視機",
        "label": "電視機"
    }, {
        "value": "無法上網",
        "label": "無法上網"
    }, {
        "value": "冰機",
        "label": "冰機"
    }, {
        "value": "冷氣機",
        "label": "冷氣機"
    }, {
        "value": "抽風/通風/空氣靜化(EP)",
        "label": "抽風/通風/空氣靜化(EP)"
    }, {
        "value": "傢俱",
        "label": "傢俱"
    }, {
        "value": "室內裝修",
        "label": "室內裝修"
    }, {
        "value": "半自動炒爐",
        "label": "半自動炒爐"
    }, {
        "value": "消防系統",
        "label": "消防系統"
    }, {
        "value": "閉路電視",
        "label": "閉路電視"
    }, {
        "value": "OPENRICE機",
        "label": "OPENRICE機"
    }, {
        "value": "隔油池 / 隔油箱",
        "label": "隔油池 / 隔油箱"
    }, {
        "value": "洗碗碟機",
        "label": "洗碗碟機",
    }, {
        "value": "對講機/擴音機/影迫設備",
        "label": "對講機/擴音機/影迫設備"
    }, {
        "value": "落單手機",
        "label": "落單手機",
    }, {
        "value": "落貨平板",
        "label": "落貨平板",
    }, {
        "value": "面相機",
        "label": "面相機",
    }, {
        "value": "排隊機",
        "label": "排隊機",
    }, {
        "value": "廠房打印機",
        "label": "廠房打印機",
    }, {
        "value": "FAX機",
        "label": "FAX機",
    }, {
        "value": "拍卡機",
        "label": "拍卡機",
    }, {
        "value": "音樂機",
        "label": "音樂機",
    }, {
        "value": "固網電話",
        "label": "固網電話",
    }
]

const RepairRecord = () => {

    const ReturnToFunctionMenu = () => {

    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.TopDiv}>
                    <div className={css.ReturnMenuBtn} onClick={() => ReturnToFunctionMenu()}>
                        <i className={`fi fi-br-angle-double-left  ${css.ReturnIcon}`}></i>
                        <p>返回功能列表</p>
                    </div>
                </div>
                <div className={css.filterDiv}>
                    <h3>維修工作消單管理</h3>
                    <div className={css.searchDiv}>
                        <div className={css.EachFilterItemDiv}>
                            <label>日期範圍  (由)</label>
                            <DatePicker
                                // value={data? .date_of_birth ? new Date(data.date_of_birth) : null}
                                // onChange={(value) => handleDateChange("date_of_birth", value as Date | null)}
                                format="dd-MM-yyyy"
                                className={css.DatePicker}
                                clearIcon={null}
                                yearPlaceholder="yyyy"
                                monthPlaceholder="mm"
                                dayPlaceholder='dd'
                                autoFocus={false}
                            />
                        </div>

                        <div className={css.EachFilterItemDiv}>
                            <label>日期範圍  (至到)</label>
                            <DatePicker
                                // value={data? .date_of_birth ? new Date(data.date_of_birth) : null}
                                // onChange={(value) => handleDateChange("date_of_birth", value as Date | null)}
                                format="dd-MM-yyyy"
                                className={css.DatePicker}
                                clearIcon={null}
                                yearPlaceholder="yyyy"
                                monthPlaceholder="mm"
                                dayPlaceholder='dd'
                                autoFocus={false}
                            />
                        </div>

                        <div className={css.EachFilterItemDiv}>
                            <label>器材分類</label>
                            <select name="EquipmentType">
                                {SelectOption.map((item, index) => (
                                    <option key={index} value={item.value}>{item.value} | {item.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className={css.EachFilterItemDiv}>
                            <label>維修員/維修商</label>
                            <select name="RepairMan">
                                {SelectOption.map((item, index) => (
                                    <option key={index} value={item.value}>{item.value} | {item.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className={css.EachFilterItemDiv}>
                            <label>進度</label>
                            <select name="RepairMan">
                                {SelectOption.map((item, index) => (
                                    <option key={index} value={item.value}>{item.value} | {item.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className={css.EachFilterItemDiv}>
                            <label>品牌</label>
                            <select name="RepairMan">
                                {SelectOption.map((item, index) => (
                                    <option key={index} value={item.value}>{item.value} | {item.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className={css.EachFilterItemDiv}>
                            <label>關鍵字搜尋</label>
                            <input type="text" value="搜尋"/>
                        </div>

                    </div>
                </div>

            </section>
        </>
    )

    return content
}

export default RepairRecord